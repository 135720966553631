<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <router-link to="/admin/page/create" class="btn btn-success">ثبت صفحه</router-link>
      <b-card title="صفحات" class="mt-3">
        <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status" >
                      <b-form-select-option value="1">فعال</b-form-select-option>
                      <b-form-select-option value="0">غیرفعال</b-form-select-option>
                      <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
              </b-form-group>
              </div>
    
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
            <span class="badge badge-light" v-else>غیرفعال</span>
          </template>

          <template v-slot:cell(edit)="data">
              <router-link :to="{ name: 'page.edit.index', params: { page: data.item.id } }" class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></router-link>
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
      </pagination>
      <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
      </pagination>

    </div>
  </template>

<script>
import mixins from '../mixins/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/page',
      title: 'صفحات',
        selected_filter_status: '',
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'title', label: 'عنوان' },
        { key: 'status', label: 'وضعیت' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' }
      ]

    }
  },
  methods: {

  },
  created () {
    this.loadItems()
    this.searchParams = {
      title:'',
      status:''
    }
  }
}
</script>
